import axios from "axios";

export default class FileUtil {

    static async fetchImage(url: string) {
        let returnValue;
        await axios.request({
            method: "get",
            url: url,
        }).then(res => {
            returnValue = res.data;
        }).catch(() => {
            console.log('Unable to fetch image ' + url);
            return require('@/assets/images/apple-icon.png');
        });
        return returnValue;
    }

    static async load_binary_resource(url: string) {
        const byteArray = [];
        const req = new XMLHttpRequest();
        req.open('GET', url, false);
        req.overrideMimeType('text/plain; charset=x-user-defined');
        req.send(null);
        if (req.status != 200) return undefined;
        for (let i = 0; i < req.responseText.length; ++i) {
            byteArray.push(req.responseText.charCodeAt(i) & 0xff)
        }
        return byteArray;
    }

    static removeFileFromFileList(index: number, fileButton: HTMLInputElement) {
        const dt = new DataTransfer()
        const { files } = fileButton

        for (let i = 0; i < files.length; i++) {
            const file = files[i]
            if (index !== i)
                dt.items.add(file) // here you exclude the file. thus removing it.
        }

        fileButton.files = dt.files // Assign the updates list
    }

    /** If given, assigns the src of the HTMLImageElement to the file-object containing the image.
     * @param img The HTMLImageElement to display the image
     * @param file The file/blob which contains the image-data
     * @param onLoad Callback function to call after the image has been loaded as DataURL. */
    static loadImage(img: HTMLImageElement, file: File, onLoad: (url: string) => void = null) {
        const reader = new FileReader();
        reader.onload = function(e) {
            const imgURL = e.target.result as string;
            if(img != undefined){
                img.src = imgURL;
            }
            if(onLoad != null)
                onLoad(imgURL);
        }
        reader.readAsDataURL(file);
    }

    static b64toBlob(b64Data: string, contentType='', sliceSize= 512) {
        b64Data = b64Data.replace(/data:\w+\/\w+;base64,/, ""); //remove metadata

        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: contentType});
    }
}