<template>
  <div class="slideshow-container">
    <a v-if="this.numberOfImages > 1" class="prev" @click="previousImage">&#10094;</a>
    <div class="media_display">
      <media-display
          class="custom-media-class"
          :src="currentMediaSrc"
          :media-i-d="currentMediaId"
          :media-type="currentMediaType"
          :get-api-call=true>
      </media-display>
      <div class="text-center" v-if="this.numberOfImages > 1">
        <span v-for="(item, index) in poc.medias" :key="index" class="dot" @click.stop="showMedia(index)"></span>
      </div>
    </div>
    <a v-if="this.numberOfImages > 1" class="next" @click="nextImage">&#10095;</a>

  </div>


</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {AFPPoc} from "@/services/model/model";
import MediaDisplay from "@/components/MediaDisplay.vue";

@Options({
  components: {MediaDisplay},
  props: ['poc'],
  emits: ['index-changed'],
})
export default class BasePagination extends Vue {


  //emits = defineEmits(["index-changed"])
  imageIndex = 0;
  numberOfImages = -1;

  @Prop() poc: AFPPoc;
  currentMediaSrc = '';
  currentMediaType = '';
  currentMediaId = 0;

  // eslint-disable-next-line no-undef


  beforeMount() {
    this.numberOfImages = this.poc.medias.length;
  }

  mounted() {
    this.showMedia(this.imageIndex);
  }

  @Watch("imageIndex")
  private onIndexChanged(newIndex: number, oldIndex: number) {

    this.$emit("index-changed", newIndex, oldIndex);
  }

  public nextImage() {
    this.showMedia(this.imageIndex + 1);
  }

  public previousImage() {
    this.showMedia(this.imageIndex - 1);
  }

  public showMedia(index: number ) {
    if (this.numberOfImages === 0) return;

    this.imageIndex = (index + this.numberOfImages) % this.numberOfImages;

    const media = this.poc.medias[this.imageIndex];
    this.currentMediaType = media.filename.slice(media.filename.lastIndexOf(".") + 1).toLowerCase();
    this.currentMediaId = media.id;



    this.currentMediaSrc = localStorage.getItem("media-" + media.id);
    if(this.currentMediaSrc === null ){
      this.$forceUpdate();
    }

    this.updateDots(this.imageIndex);
  }


  private updateDots(index: number) {
    let dots = document.getElementsByClassName("dot");
    if (dots.length > 0) {
      for (let i = 0; i < dots.length; i++) {
        dots[i].className = "dot";
      }
      dots[index].className += " active";
    }
  }


}

</script>

<style scoped>

.slideshow-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  gap: 15px;
}

.media_display{
  width:500px;
  height:auto;
  align-items: center;
}

.custom-media-class :deep(video){
  width:500px;
  height:300px;
  border-radius: 10px;
}

.custom-media-class :deep(img) {
  width:300px;
  height:auto;
  object-fit:cover;
}
.prev, .next {
  cursor: pointer;
  color: #1e1e1e;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

a:hover {
  color: #009bff;
}


.prev {
  left: 0;
  position: absolute;
  padding: 20% 10px 20% 10px;
}

.next {
  right: 0;
  position: absolute;
  padding: 20% 10px 20% 10px;
}

.dot {
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 10px 4px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}


.dot:hover {
  background-color: #717171;
}

</style>