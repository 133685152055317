<template>
  <div class="footer font-14">
    <div v-for="uri in uris" :key="uri">
      <a :href="uri.value">{{uri.key}}</a>
    </div>
    <div style="color: var(--color-gs-2)">&copy; 2022 adesso Financial Platform</div>
  </div>
</template>

<script lang="ts">
import {Vue} from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class BaseFooter extends Vue {

  @Prop() uris: Record<string, string>[] | undefined
}
</script>

<style scoped>
a {
  text-decoration: none;
  color: #535353;
}

a:hover {
  color: var(--color-blue);
  -webkit-transition : color 500ms ease-out;
  -moz-transition : color 500ms ease-out;
  -o-transition : color 500ms ease-out;
  transition : color 500ms ease-out;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;

  clear: both;
  display: flex;
  gap: 44px;
  align-items: center;
  color: #0071B9;
}

</style>