<template>
<div>
  <h3 class="color-gs-4 bold">Projects</h3>

  <div class="display-flex" id="controls">
    <button id="addButton" type="button" @click="add">
      <span class="material-icons"> add </span> New
    </button>
  </div>

  <ul>
    <li id="noProject" v-if="pocs.length === 0">No Projects</li>

    <li v-for="(poc,index) in pocs" :key="index" :class="{ 'selected' : selectedPoc === poc }">
      <div id="project" @click.self="selectProject(poc)">
        {{ poc.title }}
        <span class="font-12"> ({{ getStatusText(poc.status) }})</span>
      </div>
      <button id="removeMediaButton" @click="removeProject(poc)">
        <span class="material-icons color-attention"> delete_forever </span>
      </button>
    </li>
  </ul>
</div>
</template>

<script lang="ts">
import {Vue} from "vue-class-component";
import {AFPPoc, AFPPocStatus } from "@/services/model/model";
import { Prop } from "vue-property-decorator";
import ListUtil from "@/util/ListUtil";
import StringUtil from "@/util/StringUtil";
import PocAPI from "@/services/apiCalls/poc-api";

export default class ProjectsList extends Vue {

  @Prop() pocs: AFPPoc[];
  @Prop() changed: boolean;

  selectedPoc: AFPPoc = null;

  selectProject(poc: AFPPoc){
    if(this.selectedPoc === poc)
      return;

    if(this.selectedPoc == undefined || !this.changed || window.confirm(`'${this.selectedPoc.title}' has unsaved changes. Do you want to proceed without saving?`)){
      this.selectedPoc = poc;
      this.$emit("selected-poc", this.selectedPoc);
    }
  }

  add(){
    let newProject = new AFPPoc();
    newProject.title = "New Project";
    this.selectProject(newProject);
  }

  removeProject(poc: AFPPoc){
    if(confirm("Warning! This will permanently remove all related data describing the aFP-Project. Are you sure?")){

      PocAPI.delete(poc.id).then(() => {
        this.$emit("remove-poc", poc);

        ListUtil.remove(this.pocs, poc);
        if(this.pocs.length > 0){
          this.selectProject(this.pocs[0]);
        }
      }).catch(()=> {
        alert("Could not delete " + poc.title);
      });
    }
  }

  getStatusText(status: number){
    return StringUtil.capitalizeFirstLetter(AFPPocStatus[status], true);
  }
}
</script>

<style scoped>

ul {
  list-style-type: none;
  border-radius: 5px;
  padding-left:1px;
}

li {
  display:flex;
  flex-direction:row;
  padding:3px;
}
#project,#noProject {
  inline-size: 270px;
  overflow: auto;
  overflow-wrap: break-word;
  hyphens: manual;
  transition-duration: 0.4s;
  background: #F5DEB350;
  padding-top: 5px;
  padding-left:7px;
  border-radius:5px;
  margin-right:2px;
}

#project:hover {
  cursor: pointer;
  color: black;
  background-color:#DEB887;
}

li button {
  float: right;
}

li.selected {
  color: black;
  font-weight: bold;
}

#addButton {
  margin-top: 15px;
  border:none;
  background-color:#F5DEB3;
  border-radius:5px;
  transition-duration: 0.4s;
  padding-top: 5px;
  padding-bottom:5px;
  font-size:15px;
}
#addButton:hover {
  background-color: #DEB887;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

#removeMediaButton {
  background-color:#F5DEB3;
  border:none;
  width:35px;
  height:35px;
  border-radius:5px;
}

#removeMediaButton:hover {
  background-color:#DEB887;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

</style>