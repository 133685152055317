<template>
  <home-search imagelink="afp_logo.svg" @onInputSubmit="filterSearch"></home-search>
  <h1 class="color-gs-5">aFP Proof of Concepts</h1>
  <div class="filter-and-pocs">
    <tag-filter :pocs="pocs" @onCheckboxSubmit="filterTag" @onSelectAll="selectAll"> </tag-filter>
    <div class="grid">
      <p v-if="pocs.length === 0">Keine passenden aFP-Projekte gefunden. </p>
      <poc-card :poc="poc" :thumbnail="getThumbnail(poc.id)" v-for="(poc,index) in pocs" :key="index"
                @click.stop="setPreviewPoc(poc)"></poc-card>
    </div>
  </div>
  <teleport to="body">
    <div v-if="previewPoc !== null">
      <base-sidebar :poc="previewPoc" @close-sidebar="closeSidebar"></base-sidebar>
    </div>
  </teleport>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import BaseHeader from "@/components/home/home-search.vue";
import HomeSearch from "@/components/home/home-search.vue";
import PocCard from "@/components/home/poc-card.vue";
import {pocStorageModule} from "@/store/poc-storage";
import BaseSidebar from "@/components/base/base-sidebar.vue";
import {AFPPoc} from "@/services/model/model";
import ListUtil from "@/util/ListUtil";
import Callbacks from "@/util/Callbacks";
import APICallImpl from "@/services/apiCalls/API-Call-Impl";
import TagFilter from "@/views/TagFilter.vue";

@Options({
  components: {
    TagFilter,
    HomeSearch,
    BaseSidebar,
    PocCard,
    BaseHeader
  }
})
export default class Home extends Vue {
  pocs: AFPPoc[] = [];
  previewPoc: AFPPoc | null = null;
  created(){
    this.preFetchThumbnails();
    this.pocs.push(...pocStorageModule.pocs);
    Callbacks.$on("pocsFetched", ()=> {
      console.log("pocsFetched");
      ListUtil.clear(this.pocs);
      this.pocs.push(...pocStorageModule.pocs);
    });

    this.pocs.forEach(x=> {
      APICallImpl.fetchMedias(x.id);
    });
  }

  filterTag(newTags:string[], oldTags:string[],selectedTags:string[], tagWithPocs:Map<string,AFPPoc[]>, allSelected:boolean) {
    if (newTags != null && oldTags != null) {
      ListUtil.clear(this.pocs);
      let pocsList: AFPPoc[] = [];
      selectedTags.forEach((tagname: string) => {
        tagWithPocs.forEach((value, key) => {
          if (key == tagname) {
            value.forEach(poc => {
              if (!pocsList.includes(poc)) {
                pocsList.push(poc);
              }
            })
          }
        })
      })
      pocsList.forEach(poc => {
        this.pocs.push(poc);
      })
    }
    if(selectedTags.length == 0){
      ListUtil.clear(this.pocs);
      this.pocs.push(...pocStorageModule.pocs);
    }
  }

  selectAll(allSelected:boolean, selectedTags:string[], tagWithPocs:Map<string, AFPPoc[]>){
    if(!allSelected){
      let allTags:string[];
      allTags = Array.from(tagWithPocs.keys());
      allTags.forEach((key)=>{
        selectedTags.push(key);
      })
      ListUtil.clear(this.pocs);
      this.pocs.push(...pocStorageModule.pocs);
    }else{
      selectedTags = [];
    }
  }

  private preFetchThumbnails(){
    const lastTimeThumbsFetched = localStorage.getItem("thumb-cacheTime");
    if(lastTimeThumbsFetched == null || (Date.now() / 1000) - parseInt(lastTimeThumbsFetched) > parseInt(process.env.VUE_APP_THUMBNAIL_REFRESH_DELAY_SEC)){
      APICallImpl.fetchThumbnails(this.pocs);
    }
  }



  getThumbnail(id: number){
    return localStorage.getItem("thumb-" + id);
  }

  public setPreviewPoc(poc: AFPPoc) {
    this.previewPoc = poc;
  }

  private closeSidebar() {
    this.previewPoc = null;
  }


  filterSearch(text: string){
    if(text === ''){
      ListUtil.clear(this.pocs);
      this.pocs.push(...pocStorageModule.pocs);
      return;
    }
    const regex = new RegExp(text, "i");
    ListUtil.clear(this.pocs);
    pocStorageModule.pocs.forEach(cart => {
      if (cart.title.search(regex) != -1) {
        this.pocs.push(cart);
      }
    });
  }
}
</script>

<style scoped>

.grid {
  grid-gap: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  padding-top:62px;
}
.filter-and-pocs{
  display:flex; flex-direction:row;
}

.filter-box>button{
  margin-top: 15px;
  margin-bottom: 15px;
  padding:10px;
  border:none;
  border-radius:5px;
  transition-duration: 0.4s;
  display:flex;
  width: fit-content;
  font-size:18px;
}
.filter-box>button:hover{
  background-color: #C0C0C0;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.filter-box>button>span:nth-child(2){
  padding-left:6px;
}

.filter-tags>div:first-child{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
}

.filter-tags>div:nth-child(2){
  padding:5px;
}
.filter-tags>div:nth-child(2)>input{
  padding-left:10px;
  width:17px;
  height:19px;
}
.filter-tags>div:nth-child(2)>label{
  padding-left:10px;
  font-weight: bold;
}

.filter-tags-list>div:first-child{
  display:flex;
  flex-direction: row;
}
.filter-tags-list>div:first-child>input{
  padding-left:10px;
  margin:5px;
  width:18px;
}
.filter-tags-list>div>div{
  display:flex;
  justify-content: space-between;
  width:100%;
  border-bottom: 2px solid #DCDCDC;
}
.filter-tags-list>div>div>label{
  padding-left:8px;
  word-wrap: break-word;
  width:270px;
}

.filter-tags-list>div>div>span{
  padding-left:10px;
}

</style>