import {MediaData} from "@/services/apiCalls/poc-api";

export class PutAFPPocDTO {

    title: string;
    description: string;
    homepageUrl: string;
    confluenceUrl: string | null;
    username: string | null;
    password: string | null;
    mediaDescriptions: Map<string, string>;
    tags : Tag[];

    constructor(json?: PutAFPPocDTO | AFPPoc | undefined) {
        if(json === undefined){
            this.mediaDescriptions = new Map<string, string>();
            return;
        }

        this.title = json.title;
        this.description = json.description;
        this.homepageUrl = json.homepageUrl;
        this.confluenceUrl = json.confluenceUrl;
        this.username = json.username;
        this.password = json.password;
        if (!(json instanceof AFPPoc)) {
            this.mediaDescriptions = json.mediaDescriptions;
            this.tags = json.tags;
        }

    }
}

export class Tag {
    id: number | null;
    name: string;

}

export class AFPPoc {
    id: number;
    status: AFPPocStatus;
    title: string;
    description: string;
    homepageUrl: string;
    confluenceUrl: string | null;
    username: string | null;
    password: string | null;

    tags : Tag[];


    //MEDIA

    //pagination
    medias: Media[];

    constructor(json?: AFPPoc | undefined) {
        if(json === undefined){
            this.medias = [];
            this.tags = [];
            return;
        }
        this.id = json.id;
        this.status = json.status;
        this.title = json.title;
        this.description = json.description;
        this.homepageUrl = json.homepageUrl;
        this.confluenceUrl = json.confluenceUrl;
        this.username = json.username;
        this.password = json.password;
        this.medias = json.medias;
        this.tags = json.tags;

    }
}

export class Media {
    id: number;
    filename: string;
    description: string | null;
}

export enum AFPPocStatus {
    ACTIVE,     //erreichbar
    INACTIVE,   //nicht erreichbar
    MAINTENANCE,//momentan in Wartung
    RETIRED,    //Wird nicht weiter entwickelt
    DEPRECATED, //Wird in absehbarer Zeit durch anderes Projekt ersetzt
    COMPLETED   //Keine weiteren Tickets
}

/** stores all relevant information for the media files */
export class MediaFormData {
    file: File;
    imageURL: string | ArrayBuffer;
    media: Media;
    changeFileButton: HTMLInputElement;
    mediaWrapper: MediaData;
    mediaType: string;

}
