<template>
  <div class="card">
    <div class="card_layout">
      <div class="thumbnail">
        <img v-if="thumbnail" id="thumb" :src="thumbnail" width="204" height="132" alt=""/>
        <img v-else :src="getImage('afp_logo.svg')" width="204" height="132" alt=""/>
      </div>

      <div class="container pos-rel">
          <div class="title bold"> {{ poc.title }} </div>
          <div id="status" :class="'unselectable text-right font-12 bold poc-' + AFPPocStatus[poc.status].toLowerCase()">
            ⬤&nbsp; {{ StringUtil.capitalizeFirstLetter(AFPPocStatus[poc.status], true) }}
          </div>

        <p class="description font-12 text-left color-gs-3">{{ poc.description }}</p>

        <div class="links pos-abs-bottom">
          <div @click.stop="">
            <a class="color-blue font-12 bold display-flex align-items-center link" :href="poc.homepageUrl">
              Directlink
              <span class="material-icons"> arrow_right_alt </span>
            </a>
          </div>
          <div v-if="poc.confluenceUrl" @click.stop="">
            <a class="color-blue font-12 bold display-flex align-items-center link" :href="poc.confluenceUrl">
              Confluence
              <span class="material-icons"> arrow_right_alt </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import {AFPPoc, AFPPocStatus } from "@/services/model/model";
import { defineProps, PropType } from "vue";
import FileUtil from "@/util/FileUtil";
import StringUtil from "@/util/StringUtil";

const props = defineProps({
  poc: {
    type: Object as PropType<AFPPoc>,
    required: true
  },
  thumbnail: { //kann auch GIF sein
    type: String,
    required: false
  },
  videoUrl: {
    type: String,
    required: false
  }
});

function getImage(imgUrl: string | null = 'afp_logo.svg') {
  if (imgUrl !== null) {
    if (!imgUrl.startsWith("http")) { //check if fetched url is local (temporary)
      return require('@/assets/images/' + imgUrl);
    }
  }
  return FileUtil.fetchImage(imgUrl);
}

</script>

<style scoped>


.card {
  background: #FFFFFF 0% no-repeat padding-box;
  box-shadow: 0 3px 6px #00000017;
  border-radius: 10px;
  width: 481px;
  height: 171px;
  cursor: pointer;
}



img {
  border-radius: 10px;
  background-color: #eaf5ff; /* default background if transparent preview-image */
  /*background: linear-gradient(#00ff4c0a, #eaf5ff);*/
}

.poc-active {
  color: var(--color-green);
}

.poc-inactive {
  color: var(--color-gs-2);
}

.poc-deprecated {
  color: #d58641;
}

.poc-retired {
  color: var(--color-retired);
}
.title {
  grid-area: title;
  display:flex;
  flex-wrap: wrap;
  text-overflow: ellipsis;
  overflow:hidden;
  height:100%;

}
#status {
  grid-area: status;
  height:100%;
}

.description {
  grid-area: description;
  margin:0;
  text-overflow: ellipsis;
  overflow: hidden;
  height:100%;
}
.container {
  display: grid;
  grid-template-columns: 120px 100px;
  grid-template-rows: 47px 50px 30px;
  grid-template-areas:"title status"
                      "description description"
                      "links links";
  overflow: hidden;
  text-overflow: ellipsis;
}

.card_layout {
  display:flex;
  flex-direction:row;
  flex-wrap: wrap;
  gap:10px 20px;
  justify-content: center;
  padding-top:15px;
}

.links {
  display:flex;
  flex-flow: row wrap;
  gap:10px;
}
.card:hover {
  background-color: #F5DEB3;
}
</style>