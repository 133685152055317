import {Tag} from "@/services/model/model";

export default class ProjectFormTagMethodCollection {

    static selected(event: { target: { value: number } }, selectedSavedTag: Tag, tags: Tag[], savedTags: Tag[]) {
        selectedSavedTag = savedTags[event.target.value];
        tags.push(selectedSavedTag);
        const indexSelectedTag = savedTags.indexOf(selectedSavedTag);
        savedTags.splice(indexSelectedTag, 1);
    }

    static addTagToTags(savedTags: Tag[], tags: Tag[], tmpNewTag: string, newTag: string) {
        const savedTagsnames: string[] = [];
        savedTags.forEach(tag => {
            savedTagsnames.push(tag.name);
        });
        const tagsnames: string[] = [];
        tags.forEach(tag => {
            tagsnames.push(tag.name);
        })
        if (tags.length == 0) {
            tmpNewTag = newTag;
        } else {
            tags.forEach(tag => {
                if (!tagsnames.includes(newTag) && !savedTagsnames.includes(newTag) && newTag != "") {
                    tmpNewTag = newTag;
                }
            });
        }
        const tag = new Tag();
        if (tmpNewTag != undefined && tmpNewTag.replace(/\s/g, '').length != 0) {
            tag.name = tmpNewTag;
            tag.id = null;
            tags.push(tag);
        }
    }
    
    static removeTagFromTags(tags: Tag[], savedTags : Tag[], index:number, tagname:string){
        // let indexx = 0;
        // savedTags.forEach(tag => {
        //     if(tag.name == tagname){
        //         savedTags.splice(indexx);
        //     }
        //     indexx = indexx+1;
        // });
        // tags.splice(index, 1);
        tags.forEach(tag => {
            if(tag.name == tagname){
                savedTags.push(tag);
            }
        })
        tags.splice(index, 1);
    }
}