<template>
  <button id="backButton" @click="router().push('/')">&laquo; Back </button>
  <div>
    Weiterführende Links
    <div>
      <a href="https://confluence.adesso.de/display/afp/aFP+Homepage">Confluence</a>
    </div>
    <div class="display-flex gap-20">
      <projects-list :pocs="pocs" :changed="dataChanged" @selected-poc="updateEditingPoc"></projects-list>
      <section v-if="editingPoc !== null">
        <project-form :poc="editingPoc" @changes="updateDataChanged"></project-form>
      </section>
    </div>
  </div>


</template>

<script lang="ts">

import ProjectsList from "@/components/ProjectEdit/ProjectsList.vue";
import ProjectForm from "@/components/ProjectEdit/ProjectForm.vue";
import {Options, Vue} from "vue-class-component";
import {AFPPoc} from "@/services/model/model";
import {pocStorageModule} from "@/store/poc-storage";
import router from "@/router";

@Options({
  methods: {
    router() {
      return router
    }
  },
  components: {
    ProjectsList,
    ProjectForm
  }
})
export default class ProjectManager extends Vue {

  pocs = pocStorageModule.pocs;
  editingPoc: AFPPoc = null;
  dataChanged = false;

  updateEditingPoc(poc: AFPPoc){
    this.editingPoc = poc;
  }

  updateDataChanged(hasChanges: boolean){
    this.dataChanged = hasChanges;
  }
}
</script>

<style scoped>
.gap-20 {
  gap: 20px;
}

#backButton {
  margin-top: 15px;
  margin-bottom: 15px;
  padding:15px;
  border:none;
  background-color:#F5DEB3;
  border-radius:5px;
  transition-duration: 0.4s;
  padding-top: 5px;
  padding-bottom:5px;
  font-size:15px;
}
#backButton:hover {
  background-color: #DEB887;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}
</style>