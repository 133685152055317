<template>
  <div>
    <div class="display-flex align-items-middle gap-27">
      <img :src="require('@/assets/images/' + imageLink)" width="50" height="50" alt="">
      <base-search v-model="text" @changed="setText"
          @keyup.enter="onInputSubmit"
          @focusout="onInputSubmit"
      ></base-search>

      <button id="adminButton" @click="router.push('admin')">Admin</button>
      <div v-if="$keycloak.hasResourceRole('Admin', 'frontend')">
        <button @click="$keycloak.logout">Logout</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import BaseSearch from "@/components/base/base-search.vue";
import router from "@/router";

@Options({
  components: {
    BaseSearch
  }
})
export default class HomeSearch extends Vue {

  @Prop() imageLink = "apple-icon.png";

  text: string | undefined = "";

  setText(newText: string){
    this.text = newText;

    if(newText === ''){
      this.onInputSubmit();
    } else {
      if(parseInt(process.env.VUE_APP_SEARCH_DELAY_MS) > 0){ //shouldn't be updated immediately (maybe trigger on keyup delay?)
        setTimeout(() => this.onInputSubmit(), parseInt(process.env.VUE_APP_SEARCH_DELAY_MS));
      } else if(parseInt(process.env.VUE_APP_SEARCH_DELAY_MS) == 0){
        this.onInputSubmit();
      }
    }
  }

  onInputSubmit(){
    this.$emit('onInputSubmit', this.text)
  }

  router = router;

}
</script>

<style scoped>

.gap-27 {
  gap: 27px;
}
#adminButton {
  margin-top: 15px;
  margin-bottom: 15px;
  padding:15px;
  border:none;
  //background-color:#F5DEB3;
  border-radius:5px;
  transition-duration: 0.4s;
  padding-top: 5px;
  padding-bottom:5px;
  font-size:15px;
}

#adminButton:hover {
  background-color: #C0C0C0;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

</style>