<!--suppress ALL -->

<template>
  <div class="adminPanel">
    <h1 v-if="!isNewPoc" class="font-22 bold color-gs-5 title">Edit Project</h1>
    <h1 v-else class="font-22 bold color-gs-5  title">Create New Project</h1>

    <form class="form" id="form" enctype="multipart/form-data" autocomplete="off">
      <div class="formcontainer">
        <div class="form-control username">
          <label>Username</label>
          <input id="username" placeholder="Username" type="url" required v-model="poc.username"/>
        </div>
        <div class="form-control password">
          <label>Password</label>
          <input id="password" placeholder="Password" type="url" required v-model="poc.password"/>
        </div>
        <p class="font-16 bold color-gs-4 subtitle-1">General Information</p>

        <div class="form-control projectTitle">
          <label for="projectTitle">Project Title</label>
          <input id="projectTitle" placeholder="Title" required v-model="poc.title" :maxlength="$MAX_LENGTH_TITLE"/>
        </div>

        <div class="form-control homepageURL">
          <label for="projectHomepageUrl">Homepage Url</label>
          <input id="projectHomepageUrl" placeholder="Homepage Url" type="url" required v-model="poc.homepageUrl"
                 maxlength="255"/>
        </div>

        <div class="form-control confluenceURL">
          <label for="confluenceHomepageUrl">Confluence Url</label>
          <input id="confluenceHomepageUrl" placeholder="Confluence Url" type="url" required v-model="poc.confluenceUrl"
                 maxlength="255"/>
        </div>

        <div class="form-control categories">
          <label for="categoriesInput"> Categories </label>
          <div class="categoriesinput">
            <input id="categoriesInput" placeholder="press enter to add categories" type="text" v-model="newTag"
                    @keydown.enter="addTag(newTag)" :style="{ 'padding-left': `10px` }"/>
            <select id="dropdown" @change="selectSavedTag($event)" multiple>
              <option v-for="(savedTag,index) in savedTags" :value="index" :key="savedTag.id" class="dropdownItem">{{ savedTag.name }}
              </option>
            </select>
          </div>
          <ul class="tags">
            <li v-for="(tag,index) in tags" :key="tag" class="tag">
              {{ tag.name }}
              <button class="delete" @click="removeTag(index,tag.name)">x</button>
            </li>
          </ul>
        </div>

        <div class="form-control description">
          <label for="description">Description</label>
          <textarea id="description" :maxlength="$MAX_LENGTH_DESCRIPTION" v-model="poc.description"/>
        </div>

        <p class="font-16 bold color-gs-4 subtitle-2">Media File Upload</p>

        <div class="form-control thumbnail">
          <label for="thumbnailButton">Thumbnail</label>
          <input id="browser" type="button" :value="!hasThumbnail ? 'Browse...' : 'Change...'"
                 onclick="document.getElementById('thumbnailButton').click();"/>
          <input id="thumbnailButton" type="file" style="display: none;" :accept="$ACCEPTED_THUMBNAIL_MIME"/>
        </div>
        <div>
          <img class="thumbnailImg" id="thumbnailImage" height="45" width="80" alt=""/>
          <br>
          <button id="removeMediaButton" type="button" @click.stop="removeThumbnail()" v-if="hasThumbnail">
            <span class="material-icons color-attention"> delete_forever </span>
          </button>
        </div>


        <div class="form-control mediaFiles">
          <label for="addMediasButton">Select Media Files</label>
          <input id="browser" type="button" value="Browse..."
                 onclick="document.getElementById('addMediasButton').click();"/>
          <input id="addMediasButton" type="file" multiple="multiple" style="display: none;"
                 :accept="$ACCEPTED_MEDIA_MIME"/>

          <table id="mediaTable" v-if="mediaFormData.length > 0">
            <tr v-for="(item, index) in mediaFormData" :key="item">
              <!-- delete button-->
              <td>
                <button id="removeMediaButton" type="button" @click.stop="removeMedia(index)">
                  <span class="material-icons color-attention"> delete_forever </span>
                </button>
              </td>
              <!-- media view-->
              <td style="display: grid">
                <div class="media-view">
                  <media-display
                      class="media-display"
                      :src="item.imageURL"
                      :media-i-d=item.media.id
                      :media-type="item.mediaType"
                      :get-api-call=false
                  >
                  </media-display>
                </div>
              </td>
              <!-- media name, description-->
              <td>
                <div class="form-control">
                  <label :for="'fileSelectButton' + index">{{ item.media.filename }}
                    {{ StringUtil.byteUnitToString(item.file.size) }}</label>
                  <input id="changeButton" type="button" value="Change..." @click.stop="changeMedia(index, item)"/>
                  <input name="fileButton" :id="'fileSelectButton' + index" type="file" :accept="$ACCEPTED_MEDIA_MIME"
                         style="display: none"/>
                  <textarea class="fileDescription" :id="'fileDescription' + index" name="fileDescription" rows="2"
                            cols="50"
                            :maxlength="$MAX_LENGTH_DESCRIPTION_MEDIA" v-model="item.media.description"
                            @input="updateDescription(index, $event)"/>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div class="form-control submit">
          <button id="buttonSubmit" type="submit" class="bold font-14 color-gs-5" @click.prevent="submitForm">
            <div v-if="!isNewPoc">
              <span class="material-icons"> update </span> Submit changes
            </div>
            <div v-else>
              <span class="material-icons"> publish </span> Submit
            </div>
          </button>
        </div>
      </div>

    </form>
    <div v-if="isSubmitting" class="overlay">
      <div class="loader"></div>
    </div>

    <!-- Your existing form and other content here -->

  </div>
</template>

<script lang="ts">

import {Vue, Options} from "vue-class-component";
import {AFPPoc, Media, PutAFPPocDTO, Tag, MediaFormData} from "@/services/model/model";
import PocAPI, {MediaData} from "@/services/apiCalls/poc-api";
import APIAbstract from "@/services/api/api-abstract";
import {Prop, Watch} from "vue-property-decorator";
import {pocStorageModule} from "@/store/poc-storage";
import FileUtil from "@/util/FileUtil";
import LocalStorage from "@/store/LocalStorage";
import StringUtil from "@/util/StringUtil";
import ListUtil from "@/util/ListUtil";
import {ref, watch, nextTick} from "vue";
import MediaDisplay from "@/components/MediaDisplay.vue";
import APICallImpl from "@/services/apiCalls/API-Call-Impl";
import ProjectFormTagMethodCollection from "@/services/projectForm-Methods/project-form-tag-method-collection";
import ProjectFormMediasMethodCollection from "@/services/projectForm-Methods/project-form-medias-method-collection";



@Options({
  components: {MediaDisplay}
})

export default class ProjectForm extends Vue {

  tmpPoc: AFPPoc;
  hasChanges: boolean;
  isSubmitting: boolean;

  @Prop() poc: AFPPoc;

  @Watch("poc", {immediate: false})
  protected onPocChanged(newPoc: AFPPoc, oldPoc: AFPPoc) {
    if (newPoc != null && oldPoc != null && this.hasChanges) {
      const index = pocStorageModule.pocs.findIndex(x => x.id == this.tmpPoc.id);
      pocStorageModule.pocs[index] = this.tmpPoc; //rollback changes when switched to other poc
      console.log("rollback changes of " + this.tmpPoc.title);
    }

    this.fillForm(newPoc);
  }

  isNewPoc = false; //true: POST, false: PUT

  hasThumbnail = false;
  StringUtil = StringUtil;
  thumbnailImage: HTMLImageElement;
  thumbnailButton: HTMLInputElement;
  addMediasButton: HTMLInputElement;
  thumbnailFormData: MediaFormData;
  mediaFormData: MediaFormData[] = [];
  mediaFormDataToBedeleted: number[] = [];
  thumbnailToBeDeleted: number[] = [];
  mounted() {
    this.isSubmitting = false;
    this.thumbnailImage = document.getElementById("thumbnailImage") as HTMLImageElement;
    this.thumbnailButton = document.getElementById("thumbnailButton") as HTMLInputElement;
    this.addMediasButton = document.getElementById("addMediasButton") as HTMLInputElement;

    this.thumbnailButton.onchange = () => { //choose new thumbnailand replace old one
      const thumbnailFile = this.getButtonData(this.thumbnailButton) as File;
      this.loadThumbnail(thumbnailFile);
    };

    this.addMediasButton.onchange = () => {
      const files = this.addMediasButton.files;
      if (files) {
        ProjectFormMediasMethodCollection.loadMedia(
            files,
            this.mediaFormData,
            this.poc,
            () => this.$forceUpdate(),
            (id) => document.getElementById(id)
        );
      }
      this.addMediasButton.value = '';
    };

    this.fillForm(this.poc);
  }

  data() {
    return {
      newTag: "",
      isSubmitting: false
    };
  }

  loadThumbnail(image: File) {
    this.hasThumbnail = true;
    FileUtil.loadImage(this.thumbnailImage, image, (dataURL) => {
      this.thumbnailFormData = new MediaFormData();
      this.thumbnailFormData.imageURL = dataURL;
      this.thumbnailFormData.file = image;
      this.thumbnailImage.hidden = false;
    });
  }

  /** Called when poc from list is selected (watcher called) or when new button is clicked (triggers mounted)*/
  fillForm(newPoc: AFPPoc) {
    if (newPoc == undefined) return;
    this.tmpPoc = new AFPPoc(newPoc);
    this.isNewPoc = newPoc.id == undefined;
    if (this.isNewPoc) {
      this.savedTags = [];
      this.getAllTagsNotInPoc(newPoc);
      this.mediaFormData = [];
      this.tags = [];
      this.thumbnailFormData = undefined;
      this.thumbnailFormData = null;
      this.thumbnailImage.hidden = true;
      this.thumbnailImage.removeAttribute('src');
    } else {
      this.tags = [];
      this.savedTags = [];
      var thumbnail = APICallImpl.getSoloThumbnails(newPoc.id, this.thumbnailImage);
      if (thumbnail != null) {
        this.loadThumbnail(thumbnail);
      }
      this.mediaFormData = [];
      APICallImpl.getMedias(newPoc.id,this.mediaFormData);
      APICallImpl.getTags(newPoc.id, this.tags);
      this.getAllTagsNotInPoc(newPoc);
    }
  }


  private getAllTagsNotInPoc(newPoc: AFPPoc) {
    if (newPoc.id !== undefined) {
      APICallImpl.getAllTagsThatAreNotAlreadyInPoc(newPoc.id, this.savedTags);
    } else {
      APICallImpl.getAllTags(this.savedTags);
    }
  }


  changeMedia(index: number, item: MediaFormData) {
    const fileInput = document.getElementById('fileSelectButton' + index) as HTMLInputElement;
    if (fileInput) {
      fileInput.addEventListener('change', (event) => ProjectFormMediasMethodCollection.handleMediaChange(event, index, this.mediaFormData));
      fileInput.click();
    }
  }

  removeMedia(index: number) {
    this.mediaFormDataToBedeleted.push(index);
    this.mediaFormData.splice(index, 1);
  }
  removeThumbnail() {
    this.thumbnailFormData = undefined;
    this.thumbnailFormData = null;
    this.thumbnailButton.value = null;
    this.thumbnailImage.hidden = true;
    this.hasThumbnail = false;
    var imageKey = ("thumb-" + this.poc.id);
    if (localStorage.getItem(imageKey) != undefined) {
      localStorage.removeItem(imageKey)
    }
  }

  async removeMediaFormData(index: number) {
    var imageKey = ("media-" + this.poc.medias.at(index).id)
    if (localStorage.getItem(imageKey) != undefined) {
      localStorage.removeItem(imageKey)
    }
    this.poc.medias.splice(index, 1);
  }
  tags: Tag[] = [];
  newTag: string;
  savedTags: Tag[] = [];
  selectedSavedTag: Tag;
  tmpNewTag:string;
  selectSavedTag(event: { target: { value: number; }; }) {
    ProjectFormTagMethodCollection.selected(event,this.selectedSavedTag,this.tags,this.savedTags);
    const dropdown = document.getElementById("dropdown");
    if (this.savedTags.length == 0) {
      dropdown.style.visibility = 'hidden';
    }
  }
  addTag(tagb: any) {
    ProjectFormTagMethodCollection.addTagToTags(this.savedTags,this.tags,this.tmpNewTag,this.newTag);
    this.newTag = "";
    document.getElementById("categoriesInput").blur();
  }
  removeTag(index: number, tagname:string) {
    ProjectFormTagMethodCollection.removeTagFromTags(this.tags,this.savedTags,index,tagname);
    const dropdown = document.getElementById("dropdown");
    if(this.savedTags.length!=0){
      dropdown.style.visibility='visible';
    }
  }
  updateDescription(index: number, event: Event) {
    const textArea = event.target as HTMLTextAreaElement;
    if (textArea) {
      this.mediaFormData[index].media.description = textArea.value;
    }
  }

  /** Clears the form. Called after successful submit or a project is deleted */
  clearForm() {
    this.tmpPoc = undefined;
    this.thumbnailFormData = undefined;
    this.thumbnailFormData = null;
    this.mediaFormData = [];
    this.addMediasButton.value = null;
    this.thumbnailButton.value = null;
    this.$emit("selected-poc", null);
    this.mediaFormDataToBedeleted = [];
    this.tags = [];
    this.savedTags = [];
  }

  /** Called when the user tries to submit the form, to check for invalid inputs. */
  validateForm() {
    if (StringUtil.isEmpty(this.poc.homepageUrl)) {
      window.alert("No homepage URL specified!");
      return false;
    }
    if (!StringUtil.isUrlValid(this.poc.homepageUrl)) {
      window.alert("The homepage URL is not a valid URL!");
      return false;
    }
    if(!StringUtil.isEmpty(this.poc.username)){
      if (!StringUtil.isUrlValid(this.poc.username)) {
        window.alert("The username URL is not a valid URL");
        return false;
      }
    }
    if(!StringUtil.isEmpty(this.poc.password)){
      if (!StringUtil.isUrlValid(this.poc.password)) {
        window.alert("The password URL is not a valid URL");
        return false;
      }

    }
    if (!StringUtil.isEmpty(this.poc.confluenceUrl)) { //OPTIONAL
      if (!StringUtil.isUrlValid(this.poc.confluenceUrl)) {
        window.alert("The confluence URL is not a valid URL!");
        return false;
      }
    }
    if (this.thumbnailFormData != undefined) {
      const thumbnailFile = this.thumbnailFormData.file;
      const maxSizeThumb = StringUtil.removeUnderscore(process.env.VUE_APP_MAX_SIZE_THUMBNAIL);
      if (thumbnailFile.size > maxSizeThumb) {
        window.alert(`The Thumbnail Size (${StringUtil.byteUnitToString(thumbnailFile.size)}) exceeds the limit: ` + StringUtil.byteUnitToString(maxSizeThumb));
        return false;
      }
    }
    var isToBig = true;
    const maxSizeImage = StringUtil.removeUnderscore(process.env.VUE_APP_MAX_SIZE_IMAGE);
    this.mediaFormData.forEach(x => {
      const file = x.file;
      if (file != null) {
        if (file.size > maxSizeImage) {
          window.alert(`${file.name} exceeds the filesize limit of ` + StringUtil.byteUnitToString(maxSizeImage));
          isToBig = false;
        }
      }
    });
    return isToBig;
  }

  submitForm() {
    //Multipart request bilder und text ans Backend schicken, Backend speichert text daten in entity, sendet bilder an cmis, speichert urls in entity
    if (!this.validateForm()) {
      return;
    }

    this.isSubmitting = true;

    const putAFPPocDTO = new PutAFPPocDTO(this.poc);
    putAFPPocDTO.mediaDescriptions = new Map<string, string>();
    this.mediaFormData.forEach(x => {
      if (x.media.description !== undefined) {
        putAFPPocDTO.mediaDescriptions.set(x.media.filename, x.media.description);
      } else {
        putAFPPocDTO.mediaDescriptions.set(x.media.filename, " ");
      }
    });

    let formData = APIAbstract.stringifyToFormData("afpPoc", putAFPPocDTO);

    if (this.thumbnailFormData != undefined) {
      const thumbnailFile = this.thumbnailFormData.file;
      formData.append("thumbnail", thumbnailFile, thumbnailFile.name);
      this.hasThumbnail = true;
    }

    this.poc.tags = this.tags;
    this.tags.forEach(x => {

      // Create a File from the Blob. You can give it any name you prefer.
      const id = x.id !== null ? x.id.toString() : "null";
      const filename = x.name;
      const blob = new Blob([id], { type: "text/plain" });
      const fakeFile = new File([blob], filename,  { type: "text/plain" });

      // Append the fake file to the FormData
      formData.append("tags", fakeFile);
    });

    //MEDIA
    this.mediaFormData.forEach(x => {
      const file = x.file;
      if (file != null) {
        formData.append("media", file, x.media.filename);
        if (x.media.description === null || x.media.description === undefined) {
          formData.append(x.media.filename, "");
        } else {
          formData.append(x.media.filename, x.media.description);
        }
      }
    });

    this.mediaFormDataToBedeleted.forEach(x => {
      //delete selected medias
      this.removeMediaFormData(x);

    });

    if (!this.isNewPoc) {
      this.put(formData);
    } else {
      this.post(formData);
    }
  }

  async post(formData: FormData) {
    PocAPI.post(formData)
        .then(data => {
          const url = new URL(process.env.VUE_APP_KEYCLOAK_REDIRECT_URI);
          alert("Project successfully uploaded!");
          window.location.replace(url.toString()+"admin");
          this.clearForm();
        })
        .catch(err => {
          alert("Could not upload Project! " + err.status);
          console.log(err);
        }).finally(() => {
          this.isSubmitting = false; // Stop loading
        });
  }

  async put(formData: FormData) {
    PocAPI.put(formData, this.poc.id)
        .then(data => {
          const url = new URL(process.env.VUE_APP_KEYCLOAK_REDIRECT_URI);
          alert("Project successfully uploaded!");
          window.location.replace(url.toString()+"admin");
          this.clearForm();
        })
        .catch(err => {
          alert("Could not update Project!: " + err.status);
          console.log(err);
        }).finally(() => {
          this.isSubmitting = false; // Stop loading
        });
  }

  checkHasChanges() { //check if changes and fire event when true
    let hasChanges = false;
    if (this.tmpPoc !== undefined) {
      hasChanges = JSON.stringify(this.tmpPoc) !== JSON.stringify(this.poc);
    }
    this.hasChanges = hasChanges;
    this.$emit('changes', hasChanges);
    return hasChanges;
  }

  updated() { //called every time the DOM is updated
    this.checkHasChanges();
  }

  /** Gibt die mit dem Filechooser selektierten Dateien des Buttons aus.
   * Falls der Button auf multiple gesetzt ist, wird eine Liste ausgegeben, sonst das einzige Objekt. */
  getButtonData(x: HTMLElement) {
    let button = (x as HTMLInputElement);
    if (button.files.length <= 1) {
      return button.files[0];
    }
    return button.files;
  }
}

</script>

<style scoped>
h1 {
  text-align: center;
}


textarea {
  resize: none;
  font-family: inherit;
  font-size: 12px;
}

.form-control {
  margin: 0.5rem 0;
  display: grid;
  row-gap: 5px;
}

#buttonSubmit {
  margin-top: 15px;
  border: none;
  background-color: #F5DEB3;
  border-radius: 5px;
  transition-duration: 0.4s;
}

#buttonSubmit:hover {
  background-color: #DEB887;
}

#buttonSubmit:hover, #browser:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}


.formcontainer {
  display: grid;
  grid-template-columns:500px 500px;
  grid-template-rows: 100px 80px 100px 100px 100px 200px 130px 80px;
  column-gap: 25px;
  row-gap: 10px;
  grid-template-areas:"username password"
                      "subtitle-1 subtitle-2"
                      "projectTitle thumbnail"
                      "homepageURL thumbnailImg"
                      "confluenceURL mediaFiles"
                      "categories ."
                      "description ."
                      "submit ."
}

.username {
  grid-area: username;
}

.password {
  grid-area: password;
}

.categories {
  grid-area: categories;
  gap: 1;
}

.subtitle-1 {
  grid-area: subtitle-1;
  text-align: center;
  padding-top: 10px;
  border-radius: 5px;
}

.subtitle-2 {
  grid-area: subtitle-2;
  text-align: center;
  padding-top: 10px;
  border-radius: 5px;
}

.projectTitle {
  grid-area: projectTitle;
}

.preview {
  height: 45px; /* Höhe festlegen */
  width: 80px; /* Breite festlegen */
  object-fit: cover; /* Um sicherzustellen, dass das Bild/Video das Element füllt, ohne das Seitenverhältnis zu verzerren */
  border-radius: 5px; /* Optional: Ecken abrunden */
  margin-right: 35px;
  grid-area: preview;
}

/* Wenn Sie planen, imagePreview als Klasse zu verwenden */
/* Ersetzen Sie :id="'imagePreview' + index" mit :class="'imagePreview'" in Ihrem Vue Template */

/* Zusätzlich könnten Sie im CSS weitere Anpassungen vornehmen, wie z.B.: */
.preview :deep(img),
.preview :deep(video) {
  max-width: 75%; /* Maximal mögliche Breite ist 100% des Elternelements */
  max-height: 75%; /* Maximal mögliche Höhe ist 100% des Elternelements */
}

.homepageURL {
  grid-area: homepageURL;
}

.confluenceURL {
  grid-area: confluenceURL;
}

.description {
  grid-area: description;
}

.thumbnail {
  grid-area: thumbnail;
}

.thumbnailImg {
  grid-area: thumbnailImg;
}

.mediaFiles {
  grid-area: mediaFiles;
}

.media-grid {
  grid-area: media-grid;
}

.submit {
  grid-area: submit;
}

#browser, #changeButton {
  transition-duration: 0.4s;
  border: none;
  background-color: #F5DEB3;
  border-radius: 5px;
}

#browser:hover, #changeButton:hover, #removeMediaButton:hover {
  background-color: #DEB887;
}

#projectHomepageUrl, #confluenceHomepageUrl, #projectTitle, #description, #username, #password, .fileDescription, #categoriesInput {
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: #F5DEB3;
  opacity: 0.5;
  transition-duration: 0.4s;
}

#projectHomepageUrl:focus, #confluenceHomepageUrl:focus, #projectTitle:focus, #description:focus, #username:focus, #password:focus, .fileDescription:focus, #categoriesInput:focus {
  opacity: 1;
  outline: none;
}

#projectHomepageUrl, #confluenceHomepageUrl, #projectTitle, #browser, #username, #password, .fileDescription, #categoriesInput {
  padding: 5px;
}

.adminPanel {
  padding-bottom: 50px;
}

#mediaTable {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  height: 250px;
  width: 1fr;
}

#removeMediaButton {
  background-color: #F5DEB3;
  border: none;
  border-radius: 5px;
  width: 35px;
  height: 35px;
}

.media-view {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
}

.media-display {
  max-height: 100%;
  max-width: 100%;
}

.media-display :deep(video)::-webkit-media-controls {
  display: none !important;
}

img {
  height: 45px;
  width: 80px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}
.loader::before , .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #FFF;
  animation: prixClipFix 2s linear infinite ;
}
.loader::after{
  inset: 8px;
  transform: rotate3d(90, 90, 0, 180deg );
  border-color: #FF3D00;
}

@keyframes rotate {
  0%   {transform: rotate(0deg)}
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
  75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
}



ul {
  list-style: none;
  display: flex;
  gap: 5px;
  align-items: center;
  margin: 0;
  padding: 0;
  overflow-x: auto;
  max-width: 483px;
  scrollbar-width: thin;
}

#categoriesInput {
  height: 31.5px;
  width: 483px;
  position:relative;
}

.tag {
  background: #DEB887;
  padding: 4px;
  border-radius: 4px;
  color: white;
  white-space: nowrap;
  transition: 0.1s ease background;
}

.delete {
  color: white;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

select {
  overflow: auto;
  position: absolute;
  bottom: 300px;
  display: none;
  border: none;
  background-color: #FAEBD7;
  scrollbar-base-color: #F5DEB3;
  scrollbar-width: thin;
  raidius: 5%;
  outline: none;
  font-size:14px;
  width:300px;
  max-height:120px;
}

.categoriesinput:focus-within select:has(.dropdownItem) {
  display: block;
}

.dropdownItem{
  border-bottom: 2px solid #DEB887;
  height:25px;
  padding-left:5px;
  padding-top:1px;
}
.dropdownItem:hover{
  background-color: #DEB887;
  cursor: pointer;
}
#dropdown:has(.dropdownItem:empty){
  display:none !important;
}
</style>