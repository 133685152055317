import Keycloak, {KeycloakOnLoad} from "keycloak-js";
import StringUtil from "@/util/StringUtil";
//import {userStorageModule} from "@/store/user-storage";

export class KeycloakService {

     initOptions = {
        url: process.env.VUE_APP_KEYCLOAK_URL,
        realm: process.env.VUE_APP_KEYCLOAK_REALM,
        clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
        redirectUri: process.env.VUE_APP_KEYCLOAK_REDIRECT_URI,
        onLoad: 'login-required'
    };

    public keycloak: Keycloak;

    public onAuth: () => void;

    constructor() {
        this.keycloak = Keycloak(this.initOptions);

        this.keycloak.init({
            onLoad: this.initOptions.onLoad as KeycloakOnLoad,
            checkLoginIframe: false
        })
        .then(async (auth) => {

            if (!auth) {
                window.location.reload();
            } else {
                this.onAuth();
            }

            //userStorageModule.store_access_token(this.keycloak.idToken as string);
            // userStorageModule.store_refresh_token(this.keycloak.refreshToken as string);

            const decoded = StringUtil.parseJwt(this.keycloak.token as string);

            // userStorageModule.store_userdata({
            //     name: decoded.name,
            //     username: decoded.preferred_username,
            //     roles: decoded.realm_access.roles
            // });

            console.log(this.keycloak.idToken);
            console.log(decoded);

            //Token Refresh
            setInterval(() => {
                 // if(userStorageModule.userData.logged_out) {
                 //     this.keycloak.logout();
                 // } else {
                this.keycloak.updateToken(60).then((refreshed) => {
                    if (refreshed) {
                        console.log('Token refreshed: ' + refreshed);
                    } else {
                        console.log('Token not refreshed, valid for '
                            + Math.round(this.keycloak.tokenParsed!.exp! + this.keycloak.timeSkew! - new Date().getTime() / 1000) + ' seconds');
                    }
                }).catch(() => {
                    console.log('Failed to refresh token');
                });
                // }
            }, parseInt(process.env.VUE_APP_KEYCLOAK_JWT_REFRESH_DELAY_SEC)*1000)

        }).catch(() => {
            console.log("Authenticated Failed");
        });
    }
}