import store from '@/store/index';
import {Action, Module, Mutation, VuexModule} from 'vuex-class-modules';
import {AFPPoc} from "@/services/model/model";

@Module
export class PocStorageModule extends VuexModule { //lagert gefetchte pocs
  pocs: AFPPoc[] = [];

  @Mutation
  updatePoc(poc: AFPPoc) {
    const index = this.pocs.indexOf(poc);
    this.pocs.splice(index, 1, poc);
  }

  @Mutation
  addAFPPoc(poc: AFPPoc) {
    this.pocs.push(poc);
  }

  @Action
  actionUpdateAFPPoc(poc: AFPPoc) {
    this.updatePoc(poc);
  }

  @Action
  actionAddAFPPoc(poc: AFPPoc) {
    this.addAFPPoc(poc)
  }

  get getAFPPocs(): AFPPoc[] {
    return this.pocs;
  }
}

export const pocStorageModule = new PocStorageModule({store, name: "poc-storage"});
