import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-567eedb4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slideshow-container" }
const _hoisted_2 = { class: "media_display" }
const _hoisted_3 = {
  key: 0,
  class: "text-center"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_media_display = _resolveComponent("media-display")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (this.numberOfImages > 1)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "prev",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.previousImage && _ctx.previousImage(...args)))
        }, "❮"))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_media_display, {
        class: "custom-media-class",
        src: _ctx.currentMediaSrc,
        "media-i-d": _ctx.currentMediaId,
        "media-type": _ctx.currentMediaType,
        "get-api-call": true
      }, null, 8, ["src", "media-i-d", "media-type"]),
      (this.numberOfImages > 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.poc.medias, (item, index) => {
              return (_openBlock(), _createElementBlock("span", {
                key: index,
                class: "dot",
                onClick: _withModifiers(($event: any) => (_ctx.showMedia(index)), ["stop"])
              }, null, 8, _hoisted_4))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    (this.numberOfImages > 1)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          class: "next",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.nextImage && _ctx.nextImage(...args)))
        }, "❯"))
      : _createCommentVNode("", true)
  ]))
}