export default class StringUtil {

    static isEmpty(text: string){
        return text == undefined || text.trim() === "";
    }

    static formatDateString(dateString: string){ //Hotfix, da die datenfelder als string deklariert wurden
        const parts = dateString.split(/[.,/ -]/);
        const date = new Date(parseInt(parts[2], 10),
            parseInt(parts[1], 10) - 1,
            parseInt(parts[0], 10));
        return this.formatDate(date);
    }

    static formatDate(date: Date){
        return date.toLocaleDateString('de-DE', {year: 'numeric', day: '2-digit', month: '2-digit' });
    }

    /** Uppercases the first letter of the passed string
     * @param string The string
     * @param lowercaseOtherChars Capitalizes the first Letter of the string by lowercasing the whole string first. */
    static capitalizeFirstLetter(string: string, lowercaseOtherChars = false) {
        if(lowercaseOtherChars){
            string = string.toLowerCase();
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static SI_UNITS = [ "B", "kB", "MB", "GB", "TB", "PB", "EB" ];
    static BINARY_UNITS = [ "B", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB" ];
    static byteUnitToString(bytes: number, asPowerOf2 = false){
        if(bytes == 0)
            return "0 bytes";
        const units = asPowerOf2 ? this.BINARY_UNITS : this.SI_UNITS;
        const base = asPowerOf2 ? 1024 : 1000;

        if (bytes < base) {
            return bytes + " " + units[0];
        }
        const exponent = Math.round(Math.log(bytes) / Math.log(base));
        const unit = units[exponent];
        return (bytes / Math.pow(base, exponent)).toFixed(2) + " " + unit;
    }

    static removeUnderscore(underscoreText: string) {
        return +underscoreText.replace(/_/g, "")
    }

    static isUrlValid(url: string) {
        return /^(?=.{1,255}$)(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,246}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/.test(url);
    }

    static parseJwt (token: string) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
}