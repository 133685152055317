export default class ListUtil {

    /** Clears only the contents of the list while the object still persists and is not replaced with an empty new list. */
    static clear<T>(arr: Array<T>){
        arr.splice(0, arr.length);
    }

    /** Removes an element from the list without creating a new one. */
    static remove<T>(arr: Array<T>, item: T){
        arr.splice(arr.indexOf(item), 1);
    }
}