import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7132e2ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "display-flex align-items-middle gap-27" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_search = _resolveComponent("base-search")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: require('@/assets/images/' + _ctx.imageLink),
        width: "50",
        height: "50",
        alt: ""
      }, null, 8, _hoisted_2),
      _createVNode(_component_base_search, {
        modelValue: _ctx.text,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.text) = $event)),
        onChanged: _ctx.setText,
        onKeyup: _withKeys(_ctx.onInputSubmit, ["enter"]),
        onFocusout: _ctx.onInputSubmit
      }, null, 8, ["modelValue", "onChanged", "onKeyup", "onFocusout"]),
      _createElementVNode("button", {
        id: "adminButton",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.router.push('admin')))
      }, "Admin"),
      (_ctx.$keycloak.hasResourceRole('Admin', 'frontend'))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("button", {
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.$keycloak.logout && _ctx.$keycloak.logout(...args)))
            }, "Logout")
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}