<template>
  <div class="filter-box">
    <button @click="filterBtnClicked">
      <span class="material-icons">filter_list</span>
      <span>filter</span>
    </button>
    <div class="filter-tags" v-show="this.openFilter">
      <div>
        <span class="filter-tags-title">Filter tags</span>
        <span class="filter-tags-projects"># of projects</span>
      </div>
      <div>
        <input type="checkbox" v-model="allSelected" @click="selectAll">
        <label>All</label>
      </div>
      <div class="filter-tags-list" v-for="(key) in tagWithPocs" :key="key">
        <div>
          <input type="checkbox" v-model="selectedTags" :id="key" :value="key[0]">
          <div>
            <label :for="key">{{key[0]}}</label>
            <span> ({{key[1].length}}) </span>
          </div>
        </div>
      </div>
      <button class="clearAll"
              @click="clearAll">clear all
      </button>
      <div style="display:none">Selected tags: {{selectedTags}}</div>
    </div>
  </div>
</template>
<script lang="ts">
import {Vue} from "vue-class-component";
import {AFPPoc} from "@/services/model/model";
import ListUtil from "@/util/ListUtil";
import {pocStorageModule} from "@/store/poc-storage";
import {Prop, Watch} from "vue-property-decorator";

export default class TagFilter extends Vue {

  @Prop() allpocs:AFPPoc[]= [];
  pocs: AFPPoc[] = [];
  openFilter= false;
  selectedTags: string[] =[];
  allSelected = false;
  tagWithSize:Map<string,number>;
  tagWithPocs:Map<string,AFPPoc[]>;


  created(){
    this.fillMapsOnCreate();
  }

  filterBtnClicked() {
    this.openFilter = !this.openFilter;
    this.fillMapsOnCreate();
  }

  fillMapsOnCreate() {
    ListUtil.clear(this.allpocs);
    this.allpocs.push(...pocStorageModule.pocs);
    const tmpMap = new Map();
    const tmpMap2 = new Map();
    this.allpocs.forEach(poc =>{
      poc.tags.forEach(tag => {
        if(tmpMap2.size==0 || !tmpMap2.has(tag.name)){
          const listPocs: AFPPoc[] = [];
          listPocs.push(poc);
          tmpMap2.set(tag.name,listPocs);
        }else{
          tmpMap2.get(tag.name).push(poc);
        }
        if(tmpMap.size==0 || !tmpMap.has(tag.name)){
          tmpMap.set(tag.name,1)
        }else{
          tmpMap.set(tag.name, tmpMap.get(tag.name)+1)
        }
      })
    })
    this.tagWithSize = tmpMap;
    this.tagWithPocs = tmpMap2;
  }
  selectAll(){
    this.$emit('onSelectAll', this.allSelected, this.selectedTags, this.tagWithPocs)
    if(this.allSelected){
      this.selectedTags=[];
    }
  }
  clearAll(){
    this.selectedTags = [];
    this.allSelected = false;
    ListUtil.clear(this.pocs);
    this.pocs.push(...pocStorageModule.pocs);
  }

  @Watch("selectedTags", {immediate: false})
  protected onSelectedTagsChanged(newTags:string[], oldTags:string[]){
    if(this.allSelected == true){
      this.allSelected = false;
    }
    this.$emit('onCheckboxSubmit', newTags, oldTags, this.selectedTags, this.tagWithPocs, this.allSelected);
  }
}
</script>
<style scoped>

.filter-box {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.filter-box > button {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  transition-duration: 0.4s;
  display: flex;
  width: fit-content;
  font-size: 18px;
}

.filter-box > button:hover {
  background-color: #C0C0C0;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.filter-box > button > span:nth-child(2) {
  padding-left: 6px;
}

.filter-tags {
  background-color: #EFEFEF;
  width: 350px;
  border-radius: 5px;
  height: fit-content;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
}

.filter-tags > div:first-child {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filter-tags-title {
  font-size: 22px;
  font-weight: bold;
}

.filter-tags-projects {
  font-size: 15px;
  padding-top: 5px;
}

.filter-tags > div:nth-child(2) {
  padding: 5px;
}

.filter-tags > div:nth-child(2) > input {
  padding-left: 10px;
  width: 17px;
  height: 19px;
}

.filter-tags > div:nth-child(2) > label {
  padding-left: 10px;
  font-weight: bold;
}

.filter-tags-list {
  padding: 5px;
}

.filter-tags-list > div:first-child {
  display: flex;
  flex-direction: row;
}

.filter-tags-list > div:first-child > input {
  padding-left: 10px;
  margin: 5px;
  width: 18px;
}

.filter-tags-list > div > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 2px solid #DCDCDC;
}

.filter-tags-list > div > div > label {
  padding-left: 8px;
  word-wrap: break-word;
  width: 270px;
}

.filter-tags-list > div > div > span {
  padding-left: 10px;
}

.clearAll {
  border: none;
  width: fit-content;
  font-size:20px;
  padding:10px;
  font-weight: bold;
}

.clearAll:hover {
  color: blue;
}
</style>