<template>
  <div class="page-container">
    <div class="content-wrap">
      <router-view></router-view>
    </div>
    <base-footer :uris="uris"></base-footer>
  </div>
</template>

<script setup lang="ts">

import BaseFooter from "@/components/base/base-footer.vue";
import API_Caller from "@/services/apiCalls/API-Call-Impl";

API_Caller.loadPocs();

const uris: Record<string, string>[] = [
  {
    key: "Impressum",
    value: "https://www.adesso.de/de/impressum/index.jsp"
  },
  {
    key: "adesso Financial Platform",
    value: "/aFP"
  },
  {
    key: "Datenschutz",
    value: "/Datenschutz"
  },
  {
    key: "Nutzungshinweise",
    value: "#Nutzungshinweise"
  }
];

</script>

<style lang="scss">
@import "./assets/scss/main.scss";

html {
  background-color: #F8F8F8;
  padding: 49px 10rem 0 10rem;
  scroll-behavior: smooth;
}

.page-container {
  position: relative;
  min-height: 92vh;
}

.content-wrap {
  padding-bottom: 2.5rem;
}
</style>