import {AFPPoc} from "@/services/model/model";
import APIAbstract from "../api/api-abstract";
import dt from "@/util/data-transformer";
import APIMethod from "@/services/api/helpers/APIMethod";

/** API Request Handler für AFP ProofOfConcept Objects */
export default class PocAPI extends APIAbstract {
  static basePathSuffix = "/poc";

  static async list(forceStatusUpdate = false): Promise<AFPPoc[]> {
   return this.call("", {
     method: APIMethod.GET,
     parameters: { fetchStatus: forceStatusUpdate }
   }).then(({ data }) => {
     return dt.transformToArray(data, AFPPoc);
   });
  }

  static async get(uuid: string): Promise<AFPPoc | null> {
    return this.call(`/${uuid}`).then(({ data }) => {
      return dt.transformToClassInstance(data, AFPPoc);
    });
  }

  static async post(formData: FormData): Promise<AFPPoc | null> {
    return this.call("/", { method: APIMethod.POST, data: formData, headers: {
      "ContentType": "multipart/form-data",
        "Allowed-Origins": "*"
    } }).then(({ data }) => {
      return dt.transformToClassInstance(data, AFPPoc);
    });
  }

  static async put(formData: FormData, id: number): Promise<AFPPoc | null> {
    return this.call(`/${id}`, { method: APIMethod.PUT, data: formData, headers: {
        "ContentType": "multipart/form-data"
    } }).then(({ data }) => {
      return dt.transformToClassInstance(data, AFPPoc);
    });
  }

  static async delete(id: number): Promise<null> {
    return this.call(`/${id}`, { method: APIMethod.DELETE });
  }

  static async deleteMedia(id: number): Promise<null> {
    return this.call(`/media/${id}`, { method: APIMethod.DELETE });
  }

  // no usage
  static async getThumbnail(afpPocID: number): Promise<Blob> {
    return this.call(`/${afpPocID}/thumbnail`).then(({ data }) => {
      return new Blob([data], {
        type: "image/png",
      });
    });
  }

  static async getSoloThumbnail(afpPocID: number): Promise<ThumbnailData> {
    return this.call(`/${afpPocID}/thumbnail`).then(({ data }) => {
      const thumbnailDataJson = {
        id: afpPocID,
        base64: data
      };
      return new ThumbnailData(thumbnailDataJson);
    });
  }


  static async getThumbnails(): Promise<ThumbnailData[]> {
    return this.call('/thumbnails').then(({ data }) => {
      return dt.transformToArray(data, ThumbnailData);
    });
  }



  static async getMedias(afpPocID: number): Promise<MediaData[]>{
    return this.call(`/media/${afpPocID}/medias`).then(({ data }) => {
      return dt.transformToArray(data,MediaData);
    });
  }

  static async getSoloMedia(id: number): Promise<MediaData>{
    return this.call(`/media/${id}`).then(({ data }) => {
      const mediaDataJson = {
        id: id,
        base64: data,
        filename : "template",
        description: ""
      };
      return new MediaData(mediaDataJson);
    });
  }

  static async getTags(afpPocID: number): Promise<TagData[]>{
    return this.call(`/tag/${afpPocID}`).then(({ data }) => {

      return dt.transformToArray(data,TagData);
    });
  }

  static async getAllTagsThatAreNotAlreadyInPoc(afpPocID:number): Promise<TagData[]>{
    return this.call(`/tag/${afpPocID}/getAll`).then(({ data }) => {

      return dt.transformToArray(data,TagData);
    });
  }

  static async getAllTags(): Promise<TagData[]>{
    return this.call(`/tag/getAll`).then(({ data }) => {

      return dt.transformToArray(data,TagData);
    });
  }

}

export class ThumbnailData {
  id: number;
  base64: string;

  public constructor(json: ThumbnailData) {
    this.id = json.id;
    this.base64 = json.base64;
  }
}

export class MediaData{

  id: number;
  base64: string;
  filename: string;
  description : string | null;

  public constructor(json:MediaData) {
    this.id = json.id;
    this.base64 = json.base64;
    this.filename = json.filename;
    this.description = json.description;

  }

}

export class TagData {
  id: number;
  name: string;

  public constructor(json:TagData) {
    this.id = json.id;
    this.name = json.name;
  }
}
