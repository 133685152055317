import PocAPI from "@/services/apiCalls/poc-api";
import ListUtil from "@/util/ListUtil";
import {pocStorageModule} from "@/store/poc-storage";
import Callbacks from "@/util/Callbacks";
import {AFPPoc, Media, MediaFormData, Tag} from "@/services/model/model";
import FileUtil from "@/util/FileUtil";


export default class APICallImpl {

    static loadPocs() {
        PocAPI.list(false)
            .then(pocArray => {
                ListUtil.clear(pocStorageModule.pocs);
                pocStorageModule.pocs.push(...pocArray);
                Callbacks.$emit("pocsFetched");
                console.log("Poc Storage Elements: " + pocStorageModule.pocs.length);
            })
            .catch(err => {
                console.log(err)
                console.log("Konnte daten vom Server nicht holen");
            });
    }

    static fetchThumbnails(pocs: AFPPoc[]) {
        PocAPI.getThumbnails().then(thumbs => {
            localStorage.setItem("thumb-cacheTime", (Date.now() / 1000).toString());
            thumbs.forEach(x => {
                localStorage.setItem("thumb-" + x.id, "data:image/png;base64," + x.base64);
            });
            ListUtil.clear(pocs);
            pocs.push(...pocStorageModule.pocs);
        });
    }

    static fetchMedias(pocId: number) {
        PocAPI.getMedias(pocId).then(media => {
            media.forEach(x => {
                const key = "media-" + x.id;
                const mediaType = x.filename.slice(x.filename.lastIndexOf(".") + 1).toLowerCase();
                const mediaUrl = "data:" + (mediaType === 'mp4' ? "video/mp4" : "image/png") + ";base64," + x.base64;
                localStorage.setItem(key, mediaUrl);
            })
        });
    }

    static getSoloThumbnails(newPocId: number, thumbnailImage: HTMLImageElement): File {

        let file = null;
        PocAPI.getSoloThumbnail(newPocId).then(thumb => {
            if (thumb.base64 == "no thumbnail") {
                thumbnailImage.hidden = true;
                thumbnailImage.removeAttribute('src');
            } else {
                thumbnailImage.hidden = false;
                const thumbDataURL = "data:image/png;base64," + thumb.base64;
                localStorage.setItem("thumb-" + thumb.id, thumbDataURL);

                thumbnailImage.src = thumbDataURL;

                const blob = FileUtil.b64toBlob(thumb.base64, "png", 512);

                file = new File([blob], "thumb" + thumb.id, {type: 'image/png', lastModified: Date.now()});
            }
        });

        return file;
    }

    static getMedias(newPocId : number, mediaFormData : MediaFormData[]){
        PocAPI.getMedias(newPocId).then(loaded_medias => {

            for (let i = 0; i < loaded_medias.length; i++) { //create mediawrapper elements
                const media = loaded_medias[i];


                const blob = FileUtil.b64toBlob(media.base64, "png", 512);
                const file = new File([blob], "image" + media.id, {type: 'image/png', lastModified: Date.now()});

                const mediaWrapper = new MediaFormData();
                mediaWrapper.media = new Media();
                mediaWrapper.media.id = media.id
                mediaWrapper.media.filename = media.filename
                mediaWrapper.media.description = media.description
                mediaWrapper.file = file;

                const mediaType = media.filename.slice(media.filename.lastIndexOf(".") + 1).toLowerCase();
                mediaWrapper.mediaType = mediaType;
                let imageDataURL = "";
                imageDataURL = "data:" + (mediaType === 'mp4' ? "video/mp4" : "image/png") + ";base64," + media.base64;
                mediaWrapper.imageURL = imageDataURL;
                localStorage.setItem("media-" + media.id, imageDataURL);
                mediaFormData.push(mediaWrapper);
            }
        });
    }

    static getTags(newPocId : number, tags : Tag[]){
        PocAPI.getTags(newPocId).then(loaded_tags => {
            loaded_tags.forEach(
                tag => {
                    const newTag = new Tag();
                    newTag.id = tag.id;
                    newTag.name = tag.name;
                    tags.push(newTag);
                }
            )
        });
    }

    static getAllTagsThatAreNotAlreadyInPoc(newPocId : number, tags: Tag[]){
        PocAPI.getAllTagsThatAreNotAlreadyInPoc(newPocId).then(
            loaded_tags => {
                loaded_tags.forEach(
                    tag => {
                        const newTag = new Tag();
                        newTag.id = tag.id;
                        newTag.name = tag.name;
                        tags.push(newTag);
                    }
                )
            }
        );
    }

    static getAllTags(tags: Tag[]){
        PocAPI.getAllTags().then(
            loaded_tags => {
                loaded_tags.forEach(
                    tag => {
                        const newTag = new Tag();
                        newTag.id = tag.id;
                        newTag.name = tag.name;
                        tags.push(newTag);
                    }
                )
            }
        );
    }

}
