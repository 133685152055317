import {createApp} from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {KeycloakService} from "@/plugins/keycloakService";
//import Plugin from "@/plugins/KeycloakPlugin";

//const app = createApp(App);
//app.use(VueAxios, axios).use(store).use(router).mount('#app');
//app.use(Plugin)

const keycloakService = new KeycloakService();

/** Instantiates the Vue Application after the auth server redirected back with a session token. */
function instantiateVueApp () {
    const app = createApp(App);
    app.use(VueAxios, axios).use(store).use(router)
        //.provide('keycloak', keycloakService.keycloak)//keycloak var global verfügbar machen (mit const keycloak = inject('keycloak') benutzen
        .mount('#app');

    app.config.globalProperties.$keycloak = keycloakService.keycloak;

    app.config.globalProperties.$ACCEPTED_THUMBNAIL_MIME = process.env.VUE_APP_ACCEPTED_THUMBNAIL_MIME;
    app.config.globalProperties.$ACCEPTED_MEDIA_MIME = process.env.VUE_APP_ACCEPTED_MEDIA_MIME;
    app.config.globalProperties.$MAX_LENGTH_TITLE = process.env.VUE_APP_MAX_LENGTH_TITLE;
    app.config.globalProperties.$MAX_LENGTH_DESCRIPTION = process.env.VUE_APP_MAX_LENGTH_DESCRIPTION;
    app.config.globalProperties.$MAX_LENGTH_DESCRIPTION_MEDIA = process.env.VUE_APP_MAX_LENGTH_DESCRIPTION_MEDIA;

    console.log(keycloakService.keycloak);

    localStorage.clear(); //um blob sicherheitsfehler zu umgehen, wenn FE neu compiled wurde
}

keycloakService.onAuth = () => instantiateVueApp();
