/* eslint-disable */
const Emitter = require('tiny-emitter');
const emitter = new Emitter();

export default {
    $on: (...args: any[]) => emitter.on(...args),
    $once: (...args: any[]) => emitter.once(...args),
    $off: (...args: any[]) => emitter.off(...args),
    $emit: (...args: any[]) => emitter.emit(...args)
}

export declare type StringCallback = (arg: string) => void;
export type Callback = (...args: any[]) => void;