import {AFPPoc, Media, MediaFormData} from "@/services/model/model";
import FileUtil from "@/util/FileUtil";

export default class ProjectFormMediasMethodCollection {

    static loadMedia(
        files: FileList,
        mediaFormData: MediaFormData[],
        poc: AFPPoc,
        forceUpdateCallback: () => void,
        getElementById: (id: string) => HTMLElement | null
    ) {
        const itemsCountBefore = mediaFormData.length;

        for (let i = 0; i < files.length; i++) {
            const listIndex = itemsCountBefore + i;
            const file = files[i];

            const mediaWrapper = new MediaFormData();
            const media = new Media();
            mediaFormData.push(mediaWrapper);
            poc.medias.push(media);

            media.filename = file.name;
            mediaWrapper.media = media;
            mediaWrapper.file = file;

            FileUtil.loadImage(null, file, (dataURL) => {
                mediaWrapper.imageURL = dataURL;

                if (!file.type.match("image")) {
                    mediaWrapper.mediaType = "mp4";
                } else {
                    mediaWrapper.mediaType = "img";
                }

                forceUpdateCallback();

                const changeFileButton = getElementById("fileSelectButton" + listIndex) as HTMLInputElement;
                if (changeFileButton) {
                    changeFileButton.onchange = () => {
                        const newFile = changeFileButton.files ? changeFileButton.files[0] : null;
                        if (newFile) {
                            FileUtil.loadImage(null, newFile, (newDataURL) => {
                                media.filename = newFile.name;
                                mediaWrapper.imageURL = newDataURL;
                                mediaWrapper.file = newFile;

                                if (!newFile.type.match("image")) {
                                    mediaWrapper.mediaType = "mp4";
                                } else {
                                    mediaWrapper.mediaType = "img";
                                }
                                forceUpdateCallback();
                            });
                        }
                    };
                    mediaWrapper.changeFileButton = changeFileButton;
                }
            });
        }
    }

    static handleMediaChange(event: Event, index: number, mediaFormData: MediaFormData[]) {
        const fileInput = event.target as HTMLInputElement;
        if (fileInput && fileInput.files && fileInput.files.length > 0) {
            const file = fileInput.files[0];
            mediaFormData[index].file = file
            mediaFormData[index].media.filename = file.name;
            const reader = new FileReader();
            reader.onload = (e) => {
                const imageDataURL = e.target.result;
                mediaFormData[index].imageURL = imageDataURL;
                mediaFormData[index].media.description = "";
                localStorage.setItem("media-" + mediaFormData[index].media.id, imageDataURL as string);
            };
            reader.readAsDataURL(file);
        }
    }
}