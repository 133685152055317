<!-- MediaDisplay.vue -->
<template>
  <div class="container">
    <video ref="videoElement" id="video" :class="videoClass" v-if="isVideo" :src="src" controls ></video>
    <img ref="imageElement" id="img" :class="imageClass"  v-else :src="src"  alt="">
  </div>
</template>

<script lang="ts">
import LocalStorage from "@/store/LocalStorage";
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    mediaID: Number,
    src: String,
    mediaType: String,
    videoClass: String,
    imageClass: String,
    getApiCall: Boolean
  },
  computed: {
    isVideo(): boolean {
      return this.mediaType === 'mp4';
    }
  },
  data() {
    return {
      localSrc: this.src,
    };
  },
  methods: {
     applyMedia() {
      if(this.getApiCall){
        // Check if the elements are actually rendered in the DOM
        const videoElement = this.isVideo ? this.$refs.videoElement : null;
        const imageElement = !this.isVideo ? this.$refs.imageElement : null;


        if (videoElement) {
          LocalStorage.applyMedia(videoElement as HTMLVideoElement, this.mediaID, false, 'video');
          this.$forceUpdate();
        } else if (imageElement) {
          LocalStorage.applyMedia(imageElement as HTMLImageElement, this.mediaID);
          this.$forceUpdate();
        }
      }
    }
  },

  watch: {
    src(newSrc) {
      this.localSrc = newSrc;
      this.applyMedia();
    },
  }
})
</script>

<style>
.container{
  align-items:center;
}
#img{
  width:60%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

#video {
  object-fit: cover;
  width:100%;
}

</style>