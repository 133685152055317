import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isVideo)
      ? (_openBlock(), _createElementBlock("video", {
          key: 0,
          ref: "videoElement",
          id: "video",
          class: _normalizeClass(_ctx.videoClass),
          src: _ctx.src,
          controls: ""
        }, null, 10, _hoisted_2))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          ref: "imageElement",
          id: "img",
          class: _normalizeClass(_ctx.imageClass),
          src: _ctx.src,
          alt: ""
        }, null, 10, _hoisted_3))
  ]))
}