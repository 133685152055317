<template>
  <div class="search-box">
    <span class="material-icons"> {{icon}} </span>
    <input ref="searchField" :placeholder="placeholder" @input="updateInput($event.target.value)">
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref } from "vue";

const searchField = ref(null);
const emit = defineEmits<{
  (eventName: 'update:modelValue', newValue: string): void
  (eventName: 'changed', newValue: string): void
}>();

const props = defineProps({
  placeholder: {
    type: String,
    required: false,
    default: "Suche"
  },
  icon: {
    type: String,
    required: false,
    default: "search"
  },
  text: {
    type: String,
    required: false,
    default: null
  }
});


function updateInput(text: string) { //fire event when text is changed
  emit("update:modelValue", text);
  emit("changed", text);
}

function getInputText(){
  return searchField.value;
}

function isInputValid() {
  return "";//getInputText().trim() !== '';
}

</script>

<style scoped>

input {
  background: transparent;
  border: none;
  width: inherit;

  padding-top: 13px;
  padding-bottom: 13px;
}

input:default::placeholder {
  color: var(--color-gs-4);
}

.search-box:focus-within {
  background-color: #E3E3E3;
}

input:focus::placeholder {
  color: transparent;
}

input:focus:focus-visible {
  outline: none;
  border-color: aquamarine;
}

.search-box {
  margin: -1px;
  border: 1px solid transparent;
  border-radius: 10px;

  padding: 1px 10px 1px 10px;
  width: 327px;
  background: var(--color-gs-1) 0 0 no-repeat padding-box;

  display: flex;
  align-items: center;
}

.search-box:hover {
  margin: -1px;

  border: 1px solid #b5cde1;
  -webkit-transition : border 500ms ease-out;
  -moz-transition : border 500ms ease-out;
  -o-transition : border 500ms ease-out;
  transition : border 500ms ease-out;
}

</style>