/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import APIMethod from "./helpers/APIMethod";
import RequestConfiguration from "./helpers/RequestConfiguration";
//import { DateConverter } from "@/services/helpers/date-converter";

/**
 * Defines the basic structure of API services.
 */
export default abstract class APIAbstract {
  /// Defines the base path for the whole API, e.g. 'https://www.example.com/api/v2'.
  /// The basePath contains the default API URL for this app. Override if needed.
  /// IMPORTANT: Don't end URL with a trailing slash '/'.
  static basePath = process.env.VUE_APP_API_BASE_URL;

  /// Defines a specific suffix for a group of api calls, e.g. '/user'.
  /// IMPORTANT: Suffix needs to start with a '/'.
  /// Leave it empty if there is no suffix (don't set it to '/' in this case).
  static basePathSuffix = "";

  /**
   * Makes an API call to basePath + basePathSuffix + path.
   *
   * @param {string} path The path to the endpoint.
   * @param {RequestConfiguration} config Configuration for the request
   * @param {APIMethod} config.method HTTP request method.
   * @param {any} config.data Body data of request (optional).
   * @param {any} config.parameters Query parameters of request (optional).
   * @param {any} config.headers Additional headers for request (optional).
   * @param {any} config.responseType Expected response type (optional).
   * @returns {Promise<any>} Reponse data from request in a promise.
   */
  static async call(
    path: string,
    {
      method,
      data,
      parameters,
      headers,
      responseType,
    }: RequestConfiguration = {
      method: APIMethod.GET, //default
    }
  ): Promise<any> {
    return await axios.request({
      url: path,
      baseURL: this.basePath + this.basePathSuffix,
      method: method,
      data: data,
      params: parameters,
      headers: headers,
      responseType: responseType,
      onUploadProgress: progressEvent => console.log(progressEvent.loaded)
    });
  }

  /** Formats a JSON Object to Multipart Form data by appending each valid element of the object into the FormData instance. **/
  /*static jsonToFormData(formData: FormData, data: object, parentKey: string) {
    if (data && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.jsonToFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      if(data != null)
        formData.append(parentKey, data);
    }
  }*/

  static stringifyToFormData(key: string, item: any): FormData {
    const formData = new FormData();
    const postData = new Blob([JSON.stringify(item)], { type: "application/json" })
    formData.append(key, postData);
    return formData;
  }
}

/**
 * Formats JSON object into a standardized request object.
 *
 * - Converts null or undefined values into empty strings
 * - Converts Date objects into strings of format yyyy-MM-dd
 * @param json JSON object to format
 * @returns Formatted JSON
 */
function formatJSON(json: any) {
  // Create a copy and store formatted values in the copy
  const result: any = {};

  for (const key in json) {
    // All null and undefined values to ""
    if (json[key] === undefined || json[key] === null) {
      result[key] = "";
    }
    // Format dates
    else if (json[key] instanceof Date) {
      //result[key] = DateConverter.dateToHTMLString(json[key]);
    }
    // Recursive format objects
    else if (typeof json[key] === "object") {
      result[key] = formatJSON(json[key]);
    }
    // All primitive types
    else {
      result[key] = json[key];
    }
  }
  return result;
}

axios.interceptors.request.use(
  (config) => {
    if (["get", "post", "put", "patch"].includes(config.method ?? "")) {
      if (config.params) {
        config.params = formatJSON(config.params);
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
