import {createRouter, createWebHistory} from 'vue-router'
import Home from '@/views/Home.vue';
import ProjectManager from '@/views/ProjectManager.vue';
//import keycloakService from "@/main";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: Home,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/admin',
      component: ProjectManager,
      meta: {
        authRequired: false
      },

      /*beforeEnter(to, from, next) {
        if(!keycloakService.keycloak.authenticated) {
          keycloakService.keycloak.login();
          console.log("FORCE LOGIN");
        } else {
          console.log("Redirected to home");
          next("/")
        }
      }*/
    },
    //{path: '/:notFound(.*)', redirect: '/'},
  ]
});

/*

import {getCurrentInstance } from 'vue'

const app = getCurrentInstance();

//Wird für jeden URI-Path change aufgerufen
router.beforeEach((to, from, next) => {
  if(app === null){
    next();
    return;
  }

  const $keycloak = app.appContext.config.globalProperties.$keycloak;

  if (to.meta.authRequired) {
    console.log("AUTH REQUIRED");
    if (!$keycloak.authenticated) { // The page is protected and the user is not authenticated. Force a login.
      $keycloak.login();
      console.log("FORCE LOGIN");
    } else if ($keycloak.hasResourceRole('Admin')) { // The user was authenticated, and has the app role
      $keycloak.updateToken(70)
          .then(() => {
            next()
          })
          .catch(() => {
            //console.error(err)
          })
    } else { // The user was authenticated, but did not have the correct role. Redirect to an error page
      console.log("REDIRECT TO ERROR PAGE");
      next({ name: 'Unauthorized' })
    }
  } else { // This page did not require authentication
    console.log("next");
    next()
  }
});*/

export default router