import PocAPI from "@/services/apiCalls/poc-api";
import FileUtil from "@/util/FileUtil";

/** Funktionen für Cached data im LocalStorage */
export default class LocalStorage {

    /** Sets the image src value of the passed HTML-Image to the thumbnail from the LocalStorage
     * or initiates a GET request if nothing is cached and sets the src value of the HTML-Image while also storing the result in the localStorage for further needing.
     * The thumbnail is encoded as base64. */
    static applyThumbnail(image: HTMLImageElement, afpPocID: number, forceUpdate = false, onFetched: (data: File) => void = null) {
        const key = "thumb-" + afpPocID;

        if(!forceUpdate && localStorage.getItem(key)) {
            if(image != undefined){
                let base64 = localStorage.getItem(key);
                if(!base64.startsWith("data:image/png;base64,")){
                    base64 = "data:image/png;base64," + base64;
                }
                image.src = base64;
            }
        } else {
            if(forceUpdate){
                console.log(key + " forced to update. Initiating new request");
            } else {
                console.log(key + " does not exist in cache. Initiating new request");
            }

            PocAPI.getThumbnail(afpPocID).then(data => {
                if(data != undefined){
                    FileUtil.loadImage(image, data as File, (dataUrl)=> {
                        localStorage.setItem(key, dataUrl);
                        if(onFetched != null)
                            onFetched(data as File);
                    });
                }
            });
        }
    }

    /** Sets the image src value of the passed HTML-Image to the image from the LocalStorage
     * or initiates a GET request if nothing is cached and sets the src value of the HTML-Image while also storing the result in the localStorage for further needing.
     * The image is stored as a virtual blob and retrieved via the blob-protocol url. */
    static applyMedia(mediaElement: HTMLImageElement | HTMLVideoElement, mediaID: number, forceUpdate = false, mediaType = 'image', onFetched: (data: File) => void = null) {
        const key = "media" + "-" + mediaID;
        if (!forceUpdate && localStorage.getItem(key)) {
            if (mediaElement !== undefined) {
                if (mediaType === 'video') {
                    console.log("ich bin ein video in LocalStorage");
                    (mediaElement as HTMLVideoElement).src = localStorage.getItem(key);
                } else {
                    (mediaElement as HTMLImageElement).src = localStorage.getItem(key);
                }
            }
        } else {
            if (forceUpdate) {
                console.log(key + " forced to update. Initiating new request");
            } else {
                console.log(key + " does not exist in cache. Initiating new request");
            }

            PocAPI.getSoloMedia(mediaID).then(data => {
                if (data !== undefined) {
                    const mediaUrl = "data:" + (mediaType === 'video' ? "video/mp4" : "image/png") + ";base64," + data.base64;
                    localStorage.setItem(key, mediaUrl);

                }
            }).catch(err => {
                console.error(err);
                localStorage.removeItem(key);
                // Optionally, re-attempt the fetch with forceUpdate if needed
            });
        }
    }
}