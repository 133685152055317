/* eslint-disable @typescript-eslint/no-explicit-any */
export type Newable<T> = { new (...args: any[]): T };

export default class DataTransformer {
  /**
   * Transforms a given data object into a class instance of Type.
   * Furthermore checks, that data object can be converted into Type.
   *
   * @param {any} data Object data.
   * @param {Newable<T>} Type A class type.
   * @returns The data transformed into an instance of the given Type.
   */
  static transformToClassInstance<T>(data: any, Type: Newable<T>): T {
    const result = new Type(data);

    // Check that data is an object
    if (typeof data !== "object") {
      throw new TypeError(
        `Could not convert data into given type. Given data should be of type 'object' but is '${typeof data}'.`
      );
    }

    // Check that data object has all required fields
    for (const key of Object.keys(result)) {
      if (data[key] === undefined) {
        throw new TypeError(
          `Could not convert data into given type. Given Data is missing property '${key}'`
        );
      }
    }

    return result;
  }

  /**
   * Transforms a given data object into an array of class instances of Type.
   * Furthemore checks for every instance, that the data object can be converted into Type.
   *
   * @param {any} data Object data (an array of objects).
   * @param {Newable<T>} Type A class type.
   * @param {} elementTransformation If set, this function will be applied to every element in the array,
   *   before adding it to the result array. By default this is set to transform the element into a class instance.
   *   Using this transformation function can be useful when transforming nested structures (nested arrays, objects).
   * @returns The data transformed into an array of instances of the given Type.
   */
  static transformToArray<T>(data: any, Type: Newable<T>, elementTransformation:
      (data: any, Type: Newable<T>) => T = this.transformToClassInstance): T[] {
          if (Array.isArray(data)) {
              const resultArray: T[] = [];
              Array.from(data).forEach((element) => {
                  const transformed = elementTransformation(element, Type);
                  resultArray.push(transformed);
              });
              return resultArray;
          }
          throw new TypeError("Could not convert data into an array of given type. Given data is not an array.");
      }

      static transformToStringArray(data: any){
          if (Array.isArray(data)) {
              const resultArray: string[] = [];
              Array.from(data).forEach((element) => {
                  const transformed = element as string;
                  resultArray.push(transformed);
              });
              return resultArray;
          }
          throw new TypeError("Could not convert data into the string array. Given data is not an array.");
  }
}
