<template>
<div>
  <div class="sidebar-overlay" @click.stop="close"></div>
  <div class="sidebar">
      <button id="closeButton" @click.stop="close">
        <span class="material-icons"> close </span>
      </button>
      <div class="header font-18 bold">{{ poc.title }}</div>
      <div v-if="poc.medias.length > 0">
        <base-pagination :poc="poc" @index-changed="updatePaginationIndex"></base-pagination>
        <div class="description font-12 text-left color-gs-3">{{ poc.medias[basePaginationIndex].description }}</div>
      </div>
      <div class="description font-16 text-left color-gs-3">{{ poc.description }}</div>
  </div>
</div>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";

import BasePagination from "@/components/base/base-pagination.vue";
import {AFPPoc} from "@/services/model/model";

@Options({
  components: {
    BasePagination
  }
})
export default class BaseSidebar extends Vue {

  @Prop() poc: AFPPoc;
  basePaginationIndex = 0;

  updatePaginationIndex(newIndex: number, oldIndex: number){
    this.basePaginationIndex = newIndex;
  }

  close(){
    this.$emit("close-sidebar");
  }
}
</script>

<style scoped>

.sidebar-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  width:100%;
  height:100%;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.sidebar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 600px;
  background-color: white;
  transition: .5s;
  overflow:scroll;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar::-webkit-scrollbar {
  display: none;
}
.header {
  margin-top: 50px;
  margin-left: 25px;
  margin-right:30px;
}

.description {
  margin-top: 40px;
  margin-left: 25px;
  margin-right: 30px;
  text-align:justify;
  line-height: 1.6;
}
.description_media{
  text-align:justify;
  margin-left:25px;
}
#closeButton {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: white;
  border: none;
  font-weight: bold;
  cursor: pointer;
}

</style>